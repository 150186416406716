import { Component } from '@angular/core';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-precio',
  templateUrl: './precio.component.html',
  styleUrls: ['./precio.component.css']
})
export class PrecioComponent {

  cantidad=null;
  cantidadVotaciones=null;

  total=0;

  calcularPrecio(){

    console.log(this.cantidad);
    if(this.cantidad==null || this.cantidadVotaciones==null){
      Swal.fire('Atencion', 'Debe ingresar la cantidad de votaciones y cantidad de votantes', 'info');
    }
    else{
    var total=0;




    if(this.cantidad! >=500 && this.cantidad! <= 699){
      var total= 800*this.cantidad!*this.cantidadVotaciones;
      console.log("1");
    }
    else if(this.cantidad! >= 700 && this.cantidad! <= 999){
      var total= 750*this.cantidad!*this.cantidadVotaciones;
      console.log("2");
    }
    else if(this.cantidad! >= 1000){
      var total= 650*this.cantidad!*this.cantidadVotaciones;
      console.log("3");
    }
    else{
      console.log("4");
      var total= 900*this.cantidad!*this.cantidadVotaciones;
    }



    var iva= (total* 19)/100;

    var totalFinal= total+iva;

    this.total=totalFinal;

    Swal.fire('Atencion', 'El precio para la votación  es : $' + totalFinal +' pesos chilenos incluido el iva', 'success');
  }
  }
}
