import { Component } from '@angular/core';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-precio',
  templateUrl: './precio.component.html',
  styleUrls: ['./precio.component.css']
})
export class PrecioComponent {

  cantidad=null;
  cantidadVotaciones=null;

  total=0;

  calcularPrecio(){

    console.log(this.cantidad);
    if(this.cantidad==null || this.cantidadVotaciones==null){
      Swal.fire('Atencion', 'Debe ingresar la cantidad de votaciones y cantidad de votantes', 'info');
    }
    else{
    var total=0;




    if(this.cantidad! >= 100 && this.cantidad! <= 499){
      var total= 800*this.cantidad!*this.cantidadVotaciones;
    }
    else if(this.cantidad! >= 500 && this.cantidad! <= 999){
      var total= 700*this.cantidad!*this.cantidadVotaciones;
    }
    else if(this.cantidad! >= 1000){
      var total= 500*this.cantidad!*this.cantidadVotaciones;
    }
    else{
      var total= 850*this.cantidad!*this.cantidadVotaciones;
    }



    var iva= (total* 19)/100;

    var totalFinal= total+iva;

    this.total=totalFinal;

    Swal.fire('Atencion', 'El precio para la votación  es : $' + totalFinal +' pesos chilenos incluido el iva', 'success');
  }
  }
}
